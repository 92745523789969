import "./styles.css";
import { createDeliveryClient } from "@kontent-ai/delivery-sdk";
import { LandingPageExample } from "./LandingPage";

const app = async () => {
  // Initializes the Delivery client
  const deliveryClient = createDeliveryClient({
    environmentId: 'b101582f-5a5e-0082-0e80-cc9f0aa0baf8',
    previewApiKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIyM2JiMTczOTRlZTM0YTA3OGVhN2YzMjUwZDdkODlmMyIsImlhdCI6MTcxMjA2NDU0NywibmJmIjoxNzEyMDY0NTQ3LCJleHAiOjE3NDM2MDA0ODAsInZlciI6IjIuMC4wIiwic2NvcGVfaWQiOiJkODMyMjc5OGM3ZmE0ZGVkYTFmYWEwZDZhZDQzYzc0ZiIsInByb2plY3RfY29udGFpbmVyX2lkIjoiNzBmMjg3M2U3YzI1MDAwMzEzMjFkMGY4NDI2MTMxMGQiLCJhdWQiOiJkZWxpdmVyLmtvbnRlbnQuYWkifQ.Lsl--Uz8SZLgIHsaU4wtqW-lOHLOI62WMetYitglp14',
    defaultQueryConfig: {
      usePreviewMode: true
    }
  });

  // Gets a content item from Getting Started Project
  const response = await deliveryClient
    .item<LandingPageExample>("sample_content_item")
    .toPromise();

  // Displays content on the page
  const appDiv: HTMLElement = document.getElementById("app");
  let html: string = ``;

  const landingPage = response.data.item;

  html += `<h1>${landingPage.elements.heading.value}</h1>`;
  html += `<p>${landingPage.elements.content.value}</p>`;

  appDiv.innerHTML = html;
};

app();
